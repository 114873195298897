import React, { Component } from "react";
import { Link } from "react-router-dom";
class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="container-fluid">
          <div className="d-sm-flex justify-content-center justify-content-sm-between">
            <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
              {/* Copyright © 2021{" "} */}
              {/* <a href="#" target="_blank" rel="noopener noreferrer"> */}eKYC
              Solution of Computer Services -Certification Authority, licensed
              by the Government of Bangladesh
              {/* </a> */}
              {/* . All rights reserved. */}
            </span>
            <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
              <Link
                to="/privacy"
                style={{ color: "#277dee", marginRight: "10px" }}
              >
                Privacy Policy
              </Link>
              <Link to="/cookiespolicy" style={{ color: "#277dee" }}>
                Cookies Policy
              </Link>
            </span>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
