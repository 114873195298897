export const UploadActionEnum = {
  ONLYUPLOAD: 0,
  APKPARSE: 1,
  IMAGEUPLOAD: 2,
};

export const ModuleNameEnum = {
  DOCUMENT: "document",
};

export const PendingStatus = {
  PENDING: 0,
  COMPLETE: 1,
  REJECTED: 2,
};

export const TimeOUT = {
  timeout: 60000, ///60 seconds -- > 1 min
  timeout2: 180000, ///180 seconds --> 3 min
};
