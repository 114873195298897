import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import LocalStorageService from "../service/localStorageService";
import {
  baseURL,
  CallBackURL,
  refrsh,
  instance,
  apiinvalid,
} from "../service/ApiUrls";
import { PendingStatus } from "../Enums";
import api from "../service/ApiService";

class Navbar extends Component {
  constructor() {
    super();
    this.state = {};
    // this.callOwnProfile();
  }
  toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }
  cleanlocalStorage = () => {
    let listToRemove = [
      "pdfPageNumbers",
      "taskList",
      "fileUpload",
      "userList",
      "access_token",
      "refresh_token",
      "loggedIn",
      "username",
      "fullName",
      "myProfile",
      "selectedUserList",
      "password",
    ];
    listToRemove.map((e) => {
      localStorage.removeItem(e);
    });
  };

  getLocalProfile = () => {
    let x = localStorage.getItem("myProfile");
    return JSON.parse(x);
  };

  getStateLocalProfile = () => {
    this.setState({ ...this.getLocalProfile() }, () => {
      //console.log(this.state);
    });
  };

  componentDidMount = () => {
    // if (this.state.ownProfile === undefined) {
    // this.callOwnProfile();
    // }
    this.setState({ ...this.getLocalProfile() }, () => {
      //console.log(this.state);
    });
  };
  // componentDidUpdate = () => {
  //   this.setState({ ...this.getLocalProfile() }, () => {
  //     //console.log(this.state);
  //   });
  // };
  render() {
    return (
      <nav className="navbar col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row">
        <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">
          <Link
            className="navbar-brand brand-logo-mini align-self-center d-lg-none"
            to="/dashboard"
            onClick={(evt) => evt.preventDefault()}
          >
            <img
              src={require("../../assets/images/mini-logo.png")}
              alt="logo"
              width="30px"
            />
          </Link>
          <button
            className="navbar-toggler navbar-toggler align-self-center"
            type="button"
            onClick={() => document.body.classList.toggle("sidebar-icon-only")}
          >
            <i className="mdi mdi-menu"></i>
          </button>
          <ul className="navbar-nav navbar-nav-left header-links"></ul>
          <ul className="navbar-nav navbar-nav-right ml-lg-auto">
            <li className="nav-item  nav-profile border-0">
              <Dropdown alignRight>
                <Dropdown.Toggle className="nav-link count-indicator bg-transparent">
                  <span className="profile-text">{this.state.fullName}</span>
                  <img
                    className="img-xs rounded-circle"
                    src={require("../../assets/images/faces/user-image.png")}
                    alt="Profile"
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu className="preview-list navbar-dropdown pb-3">
                  <Dropdown.Item
                    className="dropdown-item preview-item d-flex align-items-center border-0"
                    onClick={(evt) =>
                      (window.location =
                        process.env.PUBLIC_URL + "/change-password")
                    }
                  >
                    <i className="fa fa-lock"></i> Change Password
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown-item preview-item d-flex align-items-center border-0"
                    onClick={(evt) => {
                      evt.preventDefault();
                      instance
                        .get(apiinvalid, { withCredentials: true })
                        .then((res) => {
                          this.cleanlocalStorage();
                          window.location = process.env.PUBLIC_URL + "/login";
                        });

                      //window.location = process.env.PUBLIC_URL + "/login";
                    }}
                  >
                    <i className="fa fa-power-off"></i>
                    Sign Out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            onClick={this.toggleOffcanvas}
          >
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>
    );
  }
}

export default Navbar;
