import React from "react";

export const TermsConditions = () => {
  return (
    <div className="container" id="policy" style={{ display: "block" }}>
      <h3>Terms and Conditions for Subscription</h3>
      <p style={{ textAlign: "justify" }}>
        This agreement between the subscriber/customer and eSign shall come into
        force from the date of commencement of service when the e-signature is
        activated for service after successful verification. The below terms and
        conditions will be equally applicable for all the subscribers using
        eSign eSign solution.
      </p>
      <h4>Subscriber Registration:</h4>
      <p>
        <ul className="custli2">
          <li>
            eSign shall have the right to ask for supporting documents to verify
            subscriber / customer’s identity information and validate the same
            both physically and electronically. eSign shall have the right to
            collect and verify subscriber /customer’s identification from
            government national database.
          </li>
          <li>
            Subscriber shall provide correct identity and address information
            during subscription of the service. Subscriber will be solely
            responsible if any false or incorrect information is furnished
            during registration.
          </li>
          <li>
            eSign shall have the right to suspend or offer limited services for
            giving false information during registration and/or for not
            intimating eSign of any material change in the given information
            subsequently.
          </li>
          <li>
            eSign shall have the exclusive right and jurisdiction to determine
            the title and ownership of a signature after perusing the necessary
            documents relating to that signature and shall have the right to
            suspend the service/signature in question while determining the
            same. If any dispute arises regarding the ownership/title between
            two or more subscribers or claimants, the decision provided by eSign
            in this regard shall be final and conclusive.
          </li>
          <li>
            If someone does not have NID card, he/she can purchase the service
            from eSign using a valid passport.
          </li>
          <li>
            Foreign nationals/diplomats need to produce passport and the valid
            visa or work permit or any other permit (issued by concerned
            authority to stay in Bangladesh) for purchasing the service.
          </li>
          <li>
            eSign reserves the right to reject any registration for eSign as it
            deems fit.
          </li>
          <li>
            eSign shall reserve the right to share mobile number validation
            facility against NID or other ID information to government authority
            under prevailing rules and regulation. In such case, eSign shall not
            disclose any personal information of valued subscriber/customer
            beyond legal requirement.
          </li>
        </ul>
      </p>
      <h4>Quality of Services</h4>
      <p>
        <ul className="custli2">
          <li>
            eSign shall have the right to temporarily suspend the services in
            whole or some portion of its solution for its repair, maintenance,
            in compliance with government or regulatory requirement, and shall
            not be responsible for such disruption or suspension for
            circumstances beyond eSign‘s control including but not limited to
            tide, storm, cyclone, flood, lightning, earthquake, fire, blast,
            explosion or any other act of God, war, rebellion, revolution,
            pandemic, epidemic, insurrection, embargo or sanction, blockade,
            riot, civil commotion, labor action or unrest including strike,
            lock-out or boycott, interruption or failure of any utility service,
            act of terrorism or vandalism, sabotage, unanticipated technological
            or natural interference or intrusion, loss or damage to satellites,
            loss of satellite linkage or any other data communications linkage,
            loss of connectivity or any other irresistible force or compulsion
            or any adverse Government or regulatory intervention or order.
          </li>
          <li>
            eSign shall strive to offer the best possible service quality.
            However, the quality, reliability and availability of service
            depends on various technical, physical, topological, atmospheric,
            environmental, regulatory, legal, and such other factors. eSign
            shall not be liable or responsible for any defect/deficiency in the
            same.
          </li>
        </ul>
      </p>
      <h4>Subscriber Privacy and Confidentiality</h4>
      <p>
        <ul className="custli2">
          <li>
            eSign is committed to ensure privacy and confidentiality of its
            valued subscribers. eSign does not disclose subscriber’s
            confidential information to any party during the course of business.
            However, eSign shall have the right to disclosure of or use
            customer’s information where the disclosure of the information is
            necessary for the purpose of detecting, preventing or investigating
            crime, where disclosure is deemed necessary by Controller of
            Certifying Authority (CCA) or other national security agencies and
            law enforcement agencies and disclosure is ordered by a court of
            competent jurisdiction or so provided by law.
          </li>
          <li>
            eSign shall have the right to use subscriber information for the
            purpose of internal planning, provisioning and billing for services
            , facilitating interconnection , complaint investigation ,
            supporting government in national emergencies or any other purpose
            approved by government.
          </li>
        </ul>
      </p>
      <h4>Consequences of unlawful or fraudulent activities and harassment</h4>
      <p>
        <ul className="custli2">
          <li>
            Subscriber shall not use the eSign or eSign solution for the purpose
            of illegal termination, transaction or originating service. In the
            case of any such misuse is detected by eSign or reported by
            regulatory authority, the service will be immediately disconnected,
            and appropriate legal action will be taken according to the law of
            the land.
          </li>
          <li>
            In case of any signature detected and barred for using illegal
            activities or origination purpose, customer will not be eligible to
            get refund/compensation for any unusual circumstances.
          </li>
          <li>
            Subscriber has to start using the eSign after purchasing the
            service. eSign reserves the right to temporarily discontinue or
            offer limited service in case the service remains unused for some
            period after acquisition.
          </li>
          <li>
            Subscriber will be solely responsible for all the activities and
            usage of the eSign with his valid registration process.
          </li>
          <li>
            The Subscriber warrants not to use the service(s) or eSign solution
            for any unlawful or abusive purposes, any kind of fraudulent
            activities or for sending obscene, indecent, threatening, harassing,
            unsolicited message or messages adversely affecting/ infringing upon
            national, social or economic interest, religious harmony nor create
            any damage or risk to eSign or solution and/or other
            subscribers/customers. eSign shall not bear any responsibility nor
            shall it be liable in any manner whatsoever for or in connection
            with any of the mentioned activities as well as any fraud committed
            using any of the services of eSign by any person at any time. Owner
            of the e-signature will be solely liable for such activities using
            the signature registered under his name, NID and information. eSign
            reserves the right to deactivate any such subscriber immediately and
            without prior notification.
          </li>
          <li>
            eSign shall not bear any responsibility nor shall it be liable in
            any manner whatsoever in connection with disseminating any kind of
            anti-state communication or any such communication using any of the
            services of eSign by any person at any time that might disturb
            socio-cultural , political or religious harmony of the society or
            incite disharmony therein. Owner of the signature will be solely
            liable for such activities using the signature registered under his
            name, NID and information. eSign reserves the right to disconnect
            such connection immediately and take action according to the law of
            land including disclosure of identity and usage information to the
            competent authority.
          </li>
        </ul>
      </p>
      <h4>Promotional SMS</h4>
      <p style={{ textAlign: "justify" }}>
        eSign shall reserve the right to communicate its offers and service
        related communication and/or message to its subscribers/customers,
        whatsoever as it may deem necessary in order to keep its subscribers
        /customers updated from time to time.
      </p>
      <h4>Customer Experience:</h4>
      <p>
        <ul className="custli2">
          <li>
            In case of any discrepancies related to billing, charging, value
            added service etc. from eSign authorized channels,
            subscribers/customers must raise dispute to eSign’s concerned
            channels within 30 days of receiving bill or from the date of such
            incidents experienced by the subscriber. eSign will not be bound to
            cater any such disputes otherwise.
          </li>
          <li>
            eSign provides certain self-service options and applications to
            serve subscribers/customers with one time password (OTP)
            verification. Customers need to secure their own information by not
            sharing one time password (OTP) from their end. Customer shall bear
            all consequences for sharing any personal information, one time
            password (OTP) and as such other to person/ party/association.
          </li>
          <li>
            eSign reserves the right to discontinue any free of charge (FOC)
            campaign.
          </li>
          <li>
            eSign reserves the right to change the office timing as per
            government instruction or any unavoidable situation.
          </li>
          <li>
            eSign reserves the right to determine availability of outdoor
            service based on the availability of resources, work force and over
            all safety situation.
          </li>
          <li>
            eSign reserves the right to block spammers, abusers on its social
            media and other digital & non-digital channels. eSign also reserves
            the right to block/manage otherwise customers displaying unwarranted
            violence in speech and body language, indecency or humiliating
            approach.
          </li>
          <li>
            eSign shall decide and determine where to response and take
            appropriate measures based on the content and comments published on
            third party blogs and forums.
          </li>
          <li>
            eSign may have a funny, interesting, entertaining & helpful approach
            on its digital service channels, chatbots etc. This approach is only
            to delight and entertain the customers. eSign will not be liable if
            such approach is within the parameters of the law, but is found
            objectionable by individuals of extraordinary and subjective
            sensitivity.
          </li>
          <li>
            eSign shall use artificial intelligence (AI) on its many digital
            assets, such as chatbots and website, and may ask for customers’
            personal information through it for profiling and enabling
            transactions.
          </li>
          <li>
            eSign shall ensure proper and desired customer service quality to
            the customer unless there are certain incidental issues dependent on
            various technical, environmental, regulatory and such other factors
            to ensure quality. In the case of an incidental issue, eSign will
            ensure committed service within the defined turn-around time.
          </li>
          <li>
            In case for eSign bar or unbar issue, a customer must be verified by
            sharing their registration information.
          </li>
          <li>
            eSign shall have the right to block customer service access in case
            of subscribers/customers engaged in any unethical behavior or
            fraudulent activity.
          </li>
          <li>
            Customers have the right to contact any of the customer service
            channels whenever they have any valid query, complaint, and request,
            ensuring the basic norms and manners of communication.
          </li>
          <li>
            eSign is not liable if customer is not receiving one-time password
            (OTP) or SMS.
          </li>
        </ul>
      </p>
      <h4>Additional terms and conditions relating to subscription:</h4>
      <p>
        <ul className="custli2">
          <li>
            eSign shall have the right, upon notice, to discontinue, suspend or
            bar the provision of the services to the subscriber if the total
            charges cross the total limit at any time.
          </li>
          <li>
            eSign shall have the right to discontinue, suspend or bar the
            provision of the services to the subscriber/customer if the
            monthly-billed invoice remains unpaid more than one month
            irrespective of available limit, despite due notice.
          </li>
          <li>
            eSign shall have the right to adjust security deposit with bill if
            subscriber/customer fail to clear more than six months invoices.
          </li>
          <li>
            Any complaints regarding billing must be communicated to eSign
            within thirty (30) days of respective bill generation, otherwise,
            the questioned bill shall be deemed correct.
          </li>
          <li>
            The bill due date for payment by customers shall not be more than
            one (1) month from the statement date.
          </li>
          <li>
            eSign shall have the right to change the modality of customer
            existing package after notifying subscriber.
          </li>
          <li>
            eSign shall have the right to send monthly invoice in any form
            either hard copy or electronic bill copy or through SMS or any
            medium appropriate to the customers and or based on their choice
            subject to availability of the service.
          </li>
          <li>
            The subscriber shall promptly pay all amounts due to eSign in
            accordance to eSign’s payment policy notwithstanding that the
            subscriber/customer may not have received the official bill for
            payment at the appropriate time for any reason.
          </li>
          <li>
            The subscriber shall pay the bill in the form of bank deposit or
            cash deposit at any eSign nominated bank in full as required by
            eSign. No other person or entity is responsible to pay the
            subscribers/customers bill.
          </li>
        </ul>
      </p>
      <h4>Termination of this agreement and consequences:</h4>
      <p>
        <ul className="custli2">
          <li>
            This agreement will be terminated at once the service becomes
            deactivated by the request of subscriber/ customer or appropriate
            authority of government or eSign for any valid reason.
          </li>
          <li>
            eSign shall reserve the right to preserve subscriber/ customer’s
            particulars information till the period required to comply the
            prevailing regulatory guideline and can share with competent
            authority designated by law of land.
          </li>
        </ul>
      </p>
      <h4>Subscribers with Disability</h4>
      <p>
        We are in continuous endeavor of offering equal accessibility to the
        products and services of eSign for the people who are differently able
        and may need more attention to serve.
      </p>
      <h4>Miscellaneous:</h4>
      <p style={{ marginBottom: "100px" }}>
        <ul className="custli2">
          <li>
            eSign shall have the right to amend the terms and conditions of the
            agreement in compliance with law and regulatory requirement when
            required.
          </li>
          <li>
            eSign reserves the right to change, vary, add, substitute or
            withdraw the service. eSign shall have the right to
            change/vary/increase/reduce the prices, validity periods, package
            plans and any other offers at any time on its sole discretion for
            any reason subject to official notification prior to implementation
            of such changes.
          </li>
          <li>
            Notwithstanding anything contained in this contract, any information
            brochure, notification of any other matter which will be published
            by eSign regarding its services, service price, billing policy shall
            be included as the terms and conditions of the contract and both
            eSign and its subscriber shall be under obligation of the same.
          </li>
          <li>
            Responsibilities of eSign are explicitly stated in this agreement as
            above and eSign takes no other responsibilities besides these.
          </li>
          <li>
            The laws of Bangladesh shall be applicable upon the
            subscriber/customer in availing the eSign service from eSign in
            particular, Bangladesh ICT Act. 2006 as amended from time to time
            and also the rules, regulations, guidelines and instructions emanate
            from the CCA, ICT Division, Government of Bangladesh from time to
            time.
          </li>
          <li>
            The terms and conditions stated hereinabove shall be applicable for
            all the subscribers/customers of eSign, therefore all the
            subscribers/customers of eSign being the users of the brands of
            eSign shall also adhere to the above-mentioned terms and conditions.
          </li>
          <li>
            If any section of the above procedure contradicts with the CCA, ICT
            Division’s guideline on the relevant subject, then the CCA, ICT
            Division’s guideline will prevail.
          </li>
        </ul>
      </p>
    </div>
  );
};
