import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import Spinner from "../app/shared/Spinner";
import { TermsConditions } from "./dashboard/TermsConditions";
// import FormValidation from "./user-pages/FormValidation";
const Dashboard = lazy(() => import("./dashboard/Dashboard"));
const PrivacyPolicy = lazy(() => import("./dashboard/PrivacyPolicy"));
const CookiesPolicy = lazy(() => import("./dashboard/CookiesPolicy"));
const Error404 = lazy(() => import("./user-pages/Error404"));
const Error500 = lazy(() => import("./user-pages/Error500"));
const Login = lazy(() => import("./ekyc/Login"));
const ChangePassword = lazy(() => import("./ekyc/ChangePassword"));
const ForgetPassword = lazy(() => import("./ekyc/ForgetPassword"));
const ResetPasswordPhn = lazy(() => import("./ekyc/ResetPasswordPhn"));
const ResetPasswordEmail = lazy(() => import("./ekyc/ResetPasswordEmail"));
const Foreigner = lazy(() => import("./ekyc/Foreigner"));
const NidVerify = lazy(() => import("./ekyc/NidVerify"));
const NidOcr = lazy(() => import("./ekyc/NidOcr"));
const UserType = lazy(() => import("./ekyc/UserType"));
const UserEmail = lazy(() => import("./ekyc/UserEmail"));
const UserEmailOtp = lazy(() => import("./ekyc/UserEmailOtp"));
const UserEmailOtp2 = lazy(() => import("./ekyc/UserEmailOtp2"));
const UserPhnOtp = lazy(() => import("./ekyc/UserPhnOtp"));
const UserPhnOtp2 = lazy(() => import("./ekyc/UserPhnOtp2"));
const DocumentUpload = lazy(() => import("./ekyc/DocumentUpload"));
const ErrorUnauthorized = lazy(() => import("./user-pages/ErrorUnauthorized"));

class AppRoutes extends Component {
  render() {
    const loggedIn = localStorage.getItem("loggedIn");
    const AppsRoute = (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route exact path="/" component={UserType} />
          <Route path="/login" component={Login} />
          <Route path="/useremail-otp" component={UserEmailOtp} />
          <Route path="/email-otp" component={UserEmailOtp2} />
          <Route path="/useremail" component={UserEmail} />
          <Route path="/userphn-otp" component={UserPhnOtp} />
          <Route path="/phone-otp" component={UserPhnOtp2} />
          {/* <Redirect from="/userphn-otp" to="/new-path" /> */}
          {/* <Route exact path="/foreigner">
            {loggedIn ? <Redirect to="/" /> : <Foreigner />}
          </Route> */}
          <Route path="/foreigner" component={Foreigner} />
          <Route path="/nid-verify" component={NidVerify} />
          <Route path="/nid-ocr" component={NidOcr} />
          <Route path="/document-upload" component={DocumentUpload} />
          <Route path="/forget-password" component={ForgetPassword} />
          <Route path="/reset-password-phone" component={ResetPasswordPhn} />
          <Route path="/reset-password-email" component={ResetPasswordEmail} />
          <Route path="/privacy" component={PrivacyPolicy} />
          <Route path="/cookiespolicy" component={CookiesPolicy} />
          <Route path="/termandcondition" component={TermsConditions} />
          <ProtectedRoute exact path="/dashboard" component={Dashboard} />
          <ProtectedRoute path="/change-password" component={ChangePassword} />
          <Route path="/unauthorized" component={ErrorUnauthorized} />
          {/* <Route component={Error404} /> */}
          {/* <Route path="/form-validation" component={FormValidation} /> */}
          <ProtectedRoute path="/user-pages-error-500" component={Error500} />

          {/* <Redirect to="/" /> */}
        </Switch>
      </Suspense>
    );
    return <>{AppsRoute}</>;
  }
}

export default AppRoutes;
